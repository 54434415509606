import React from "react"
import { graphql } from "gatsby"
import BlogLoop from '../components/blog/blog-post-archive-Loop';
import Filters from '../components/blog/Filters'

const BlogIndex = ({
  data,
  pageContext: { nextPagePath, previousPagePath, totalPages, currentPage, taxonomy },
}) => {
  const posts = data.posts.nodes
  const locations = data.locations.nodes
  const types = data.types.nodes

  return (
    <div>
      <Filters 
        types={types}
        locations={locations}
      />
      <BlogLoop
      posts={posts}
      postCat={'/'+taxonomy}
      postCatOverride={true}
      nextPagePath={nextPagePath}
      previousPagePath={previousPagePath}
      totalPages={totalPages}
      currentPage={currentPage} 
      />
    </div>
  )
}

export default BlogIndex

export const pageQuery = graphql`
  query BlogTaxonomyTypeQuery(
      $offset: Int!
      $postsPerPage: Int!
      $taxonomy: String!
    ) {
    posts: allWpPost(
      sort: { fields: [date], order: DESC }
      limit: $postsPerPage
      skip: $offset,
      filter: {storiesTagType: {nodes: {elemMatch: {slug: {eq: $taxonomy}}}}}
    ) {
      nodes {
        excerpt
        uri
        date(formatString: "MMMM DD, YYYY")
        title
        link
        slug
        excerpt
        categories {
          nodes {
            slug
          }
        }
        acf_customFeaturedImage {
          featuredImage {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
      }
    }

    types:  allWpStoryTagType(filter: {count: {gt: 0}}) {
      nodes {
        slug
        name
      }
    }

    locations: allWpStoryTagLocation(filter: {count: {gt: 0}}) {
      nodes {
        name
        slug
      }
    }


  }
`
