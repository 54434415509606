import React, {createRef, useState} from 'react'
import styled from 'styled-components';
import Arrow from '../../assets/site-router-arrow.inline.svg'
import {Link} from 'gatsby';

const FiltersWrap = styled.div`
padding:4rem 0 0 0;

    .filterButtons{
    width:100%;
    list-style:none;
    display:inline-flex;
    z-index:10;
    position:relative;

        li{
        margin-right:4rem;

            button{
            background:none;
            font-size:2rem;
            border:0;
            z-index:20;
            font-family:var(--font-bold);
            cursor: pointer;
            display:flex;

                &:focus{
                outline:none;
                }

                span{
                transition:0.5s all ease-in-out;
                }

                .arrow-wrapper{
                    transition:0.5s all ease-in-out;    
                }

                svg{
                height:30px;
                margin-left:10px;
                transition:0.5s all ease-in-out;

                    polygon{
                    transition:0.5s all ease-in-out;
                    }

                }

                &:hover{

                    span{
                        color:var(--red);
                    }

                    svg{
                        polygon{
                        fill:var(--red);
                        }
                    }

                    .arrow-wrapper{
                    animation: slide-bottom 0.75s cubic-bezier(0.895, 0.030, 0.685, 0.220) infinite alternate both;
                    }
                }
                
                &.active{
                    span{
                        color:var(--red);
                    }
                    
                    .arrow-wrapper{
                        animation: slide-bottom 0.75s cubic-bezier(0.895, 0.030, 0.685, 0.220) infinite alternate both;
                    }

                    svg{
                    transform:rotate(-180deg);
                        polygon{
                        fill:var(--red);
                        }
                    }
                }
                
                @keyframes slide-bottom {
                    0% {
                        -webkit-transform: translateY(0);
                                transform: translateY(0);
                    }
                    100% {
                        -webkit-transform: translateY(10px);
                                transform: translateY(10px);
                    }
                }

            }
        }

    }

`

const FilterDrawers = styled.div`
min-height:${props => props.maxHeight};
overflow:hidden;
z-index:0;
position:relative;
transition:0.5s all ease-in-out;

    .filterDrawer{
    transform:translateY(-100%);
    position: absolute;
    display:flex;
    flex-wrap:wrap;
    padding:4rem 0 0 0;
    pointer-events:none;
    transition:0.5s all ease-in-out;

        &.active{
            transform:translateY(0%);
            pointer-events:all;
        }

        a{
        padding:0.6rem 1rem 0.5rem 1rem;
        border:1px solid var(--black);
        position:relative;
        margin:0 1rem 1rem 0;
        text-decoration:none;
        color:var(--black);
        font-size:0.9rem;
        transition:0.5s all ease-in-out;

            &:hover{
            background:var(--black);
            color:var(--white);
            }

        }

    }

`

export default function Filters({types, locations, className}) {

    const [maxHeight, setMaxHeight] = useState(0);

    const handleClick = (e) =>{

        if(e.target.parentNode.classList.contains('active')){
            e.target.parentNode.classList.remove('active')
        }else{
            e.target.parentNode.classList.add('active')
        }

        


        if(e.target.innerText === 'Type'){
            locationsFilterDrawer.current.classList.remove('active');
            // if(e.target.parentNode.parentNode.classList.contains('location')){
                
            // }

            filters.current.querySelector('.location button').classList.remove('active');
            if(!typeFilterDrawer.current.classList.contains('active')){
                typeFilterDrawer.current.classList.add('active');
                setMaxHeight(typeFilterDrawer.current.offsetHeight+'px');
            }else{
                typeFilterDrawer.current.classList.remove('active');
                setMaxHeight('0px');
            }
        }
        
        if(e.target.innerText === 'Location'){
            typeFilterDrawer.current.classList.remove('active');
            filters.current.querySelector('.type button').classList.remove('active');
            if(!locationsFilterDrawer.current.classList.contains('active')){
                locationsFilterDrawer.current.classList.add('active');
                setMaxHeight(locationsFilterDrawer.current.offsetHeight+'px');
                
            }else{
                locationsFilterDrawer.current.classList.remove('active');
                setMaxHeight('0px');
            }
        }
    }

    const locationsFilterDrawer = createRef();
    const typeFilterDrawer = createRef();
    const filters = createRef();

    return (
        <FiltersWrap ref={filters}  className={className}>
           <div className="wrapper">
                <ul className="filterButtons">
                    <li className="type">
                        <button onClick={handleClick}>
                            <span>Type</span>
                            <div className="arrow-wrapper">
                                <Arrow />
                            </div>
                        </button>
                    </li>
                    <li className="location">
                        <button onClick={handleClick}>
                            <span>Location</span>
                            <div className="arrow-wrapper">
                                <Arrow />
                            </div>
                        </button>
                    </li>
                </ul>
                <FilterDrawers className="filterDrawers" maxHeight={maxHeight}>
                    <div className="filterDrawer" ref={typeFilterDrawer}>
                        {
                            types.map((type, index) => {
                                return(
                                    <Link key={type+index} to={'/'+type.slug}>{type.name}</Link>
                                )
                            })
                        }
                    </div>
                    <div className="filterDrawer" ref={locationsFilterDrawer}>
                        {
                            locations.map((location, index) => {
                                return(
                                    <Link key={location+index} to={'/'+location.slug}>{location.name}</Link>
                                )
                            })
                        }
                    </div>
               </FilterDrawers>
               
            </div>
        </FiltersWrap>
    )
}
